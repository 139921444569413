window.angular
    .module('MyHippoProducer')
    .directive('createPolicyBtn', function ($mdDialog, APIService, $state, FlagshipService, ReactRedirectService) {
        const {
            FLAGSHIP_CAMPAIGN_ID,
        } = window.appConfig;
        return {
            restrict: 'E',
            scope: {},
            template: `
            <div ng-hide="isBuilder">
                <a ui-sref="portal.policyCreate" ng-if="!shouldDisablePolicyCreation && !shouldRedirectToReact" class="btn btn-create">
                    <img src='../../img/icon-plus.svg' alt="plus-icon"/>
                    GET QUOTE
                </a>
                <a href="" ng-if="!shouldDisablePolicyCreation && shouldRedirectToReact" class="btn btn-create" ng-click="redirectToReact()">
                    <img src='../../img/icon-plus.svg' alt="plus-icon"/>
                    GET QUOTE
                </a>
            </div>
        `,
            controller: function ($scope, $http, $log, UserService) {
                'ngInject';
                $scope.shouldDisablePolicyCreation = UserService.isPolicyCreationDisabled();
                $scope.isBuilder = UserService.isBuilder();

                // Get Flagship config
                $scope.shouldRedirectToReact = false; // default value
                FlagshipService.getCampaignData({ campaignID: FLAGSHIP_CAMPAIGN_ID }).then((res) => {
                    $scope.shouldRedirectToReact =
                        res.data &&
                        res.data.variation &&
                        res.data.variation.modifications &&
                        res.data.variation.modifications.value &&
                        res.data.variation.modifications.value.reactRedirect;
                });



                $scope.redirectToReact = () => {
                    var bathPath = `v2/quote-flow/address`;
                    var baseUrl = ReactRedirectService.isLocalHost() ? `http://${ReactRedirectService.getLocationHostName()}:4000/` : './';
                    APIService.findAvailableOrganizationsForUser()
                        .then((organizations) => {
                            const availableOrganizationsToCreateFrom = organizations.filter(o => o.is_producer && o.disable_policy_creation !== true);
                            if (availableOrganizationsToCreateFrom.length > 1) {
                                return $mdDialog.show({
                                    template: `<mh-select-policy-creation-modal choices="orgChoices"></mh-select-policy-creation-modal>`,
                                    controller: function ($scope) { $scope.orgChoices = availableOrganizationsToCreateFrom; }
                                }).then(({ id }) => {
                                    if (id) {
                                        var basePathWithOrgId = `${bathPath}?orgId=${id}`;
                                        window.location.href = `${baseUrl}${basePathWithOrgId}`;
                                    } else {
                                        const returnPath = $state.current.name || 'portal.policiesList';
                                        $state.go(returnPath);
                                    }

                                });
                            } else {
                                window.location.href = `${baseUrl}${bathPath}`;
                            }
                        });
                };
            },
        };
    });
